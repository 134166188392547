export default class Navigation {
  constructor(dom) {
    this.dom = dom;
    this.navBtn = undefined;
    this.navLayer = this.dom.querySelector("[js-nav-layer]");
    this.expanded = false;
    this.updateNavHeightCssVar();
    this.initNavButton();
    // this.setNavLayerPosition();
    // this.setHideOnScroll();
    window.addEventListener("resize", () => this.resize());
  }

  setHideOnScroll() {
    this.hidden = false;
    this.scroller = document.querySelector("[js-scroller]");
    this.oldTop = this.scroller.scrollTop;
    this.scroller.addEventListener("scroll", () => {
      if (this.expanded) {
        this.navBtn.classList.remove("shy");
        this.navLayer.classList.remove("showup");
        this.expanded = false;
      }

      let currTop = this.scroller.scrollTop;
      if (currTop > this.oldTop + 10 && this.hidden == false) {
        this.hideElement();
      } else if (currTop < this.oldTop - 5 && this.hidden == true) {
        this.showElement();
      }
      this.oldTop = currTop;
    });
  }

  hideElement() {
    this.dom.classList.add("shy");
    this.hidden = true;
  }

  showElement() {
    this.dom.classList.remove("shy");
    this.hidden = false;
  }

  initNavButton() {
    this.navBtn = this.dom.querySelector("[js-btn-menu]");
    this.navBtn.addEventListener("pointerenter", () => {
      this.navBtn.classList.add("shy");
      this.navLayer.classList.add("showup");
      this.expanded = true;
    });
    this.navLayer.addEventListener("pointerleave", () => {
      this.navBtn.classList.remove("shy");
      this.navLayer.classList.remove("showup");
      this.expanded = false;
    });
  }

  setNavLayerPosition() {
    this.navLayer.style.left = `${this.navBtn.offsetLeft}px`;
    this.navLayer.style.top = `${this.navBtn.offsetTop}px`;
  }

  updateNavHeightCssVar() {
    document.body.style.setProperty(
      "--navHeight",
      `${this.dom.clientHeight}px`
    );
  }

  resize() {
    this.updateNavHeightCssVar();
    // this.setNavLayerPosition();
    // console.log("resize from Navigation.js");
  }
}
