import Navigation from "./components/Navigation";
import CustomCursor from "./utils/CustomCursor";

export default class Common {
  constructor() {}

  init() {
    this.initNavigation();
    this.initCustomCursor();
  }

  initNavigation() {
    let navDOM = document.querySelector("nav");
    new Navigation(navDOM);
  }

  initCustomCursor() {
    const cursor = new CustomCursor(
      document.querySelector("[js-scroller]"),
      "cursor"
    );
    const aTags = document.querySelectorAll("a");
    cursor.addModifierClass(aTags, "hover");

    const footer = document.querySelectorAll("footer, [js-btn]");
    cursor.addModifierClass(footer, "invert");
  }
}
