export default class ResponsiveHandler {
  constructor() {
    this.touched = false;
    window.addEventListener(
      "touchstart",
      () => {
        this.touched = true;
        document.body.classList.add("isTouch");
      },
      {
        once: true,
      }
    );
    window.addEventListener("resize", () => this.update());

    this.update();
  }
  update() {
    this.updateVhCssVar();
    this.handleBreakpoint();
  }
  isTouch() {
    return this.touched;
  }
  getBreakpoint() {
    return this.breakpoint;
  }
  updateVhCssVar() {
    document.body.style.setProperty("--vh", `${window.innerHeight}px`);
  }
  handleBreakpoint() {
    let newBP = checkBP.bind(this)();
    if (this.breakpoint != newBP) {
      window.dispatchEvent(
        new CustomEvent("onBreakpointChange", {
          detail: {
            from: this.breakpoint,
            to: newBP,
          },
        })
      );
      this.breakpoint = newBP;
    }
  }
}

function checkBP() {
  let ww = window.innerWidth;
  if (ww >= 1400) return "xxl";
  else if (ww >= 1200) return "xl";
  else if (ww >= 992) return "lg";
  else if (ww >= 768) return "md";
  else if (ww >= 576) return "sm";
  return "xs";
}
