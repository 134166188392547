import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";

import { GlitchPass } from "three/examples/jsm/postprocessing/GlitchPass";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { RGBShiftShader } from "three/examples/jsm/shaders/RGBShiftShader";
import { PixelShader } from "three/examples/jsm/shaders/PixelShader";

import * as WWW from "../utils/WWWFunctions";

import GUI from "lil-gui";

export default class ThreeCanvas {
  constructor(domID) {
    this.parentDOM = document.querySelector(domID);
    this.canvas = document.createElement("canvas");
    this.parentDOM.appendChild(this.canvas);
    this.scene = new THREE.Scene();
    // this.scene.background = new THREE.Color(0xededed);
    this.sizes = {
      width: this.parentDOM.clientWidth,
      height: this.parentDOM.clientHeight,
    };
    this.time = 0;

    this.variables = {
      frequency: 1.0,
      amplitude: 0.4,
      maxDistance: 1.5,
      speed: 0.03,
      backgroundColor: { r: 0, g: 0, b: 0 },
      vertexColor: { r: 1, g: 1, b: 1 },
    };

    this.frequency = 3.35;
    this.amplitude = 0.7;
    this.speed = 0.05;

    this.gui = new GUI();
    this.gui.add(this.variables, "frequency", 0.0, 15.0, 0.01);
    this.gui.add(this.variables, "amplitude", 0.1, 1.0, 0.01);
    this.gui.add(this.variables, "speed", 0.01, 1.0, 0.01);
    this.gui.addColor(this.variables, "backgroundColor");
    this.gui.addColor(this.variables, "vertexColor");
    this.gui.hide();
  }

  async init() {
    /* Shaders */
    this.vShader = await fetch("assets/shaders/vertexParticles.glsl");
    this.fShader = await fetch("assets/shaders/fragment.glsl");

    /* Renderer */
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: true,
      alpha: true,
    });
    this.renderer.setSize(this.sizes.width, this.sizes.height);
    this.renderer.setPixelRatio(Math.min(2, window.devicePixelRatio));
    this.renderer.setClearColor(0x000000, 0);

    /* Camera */
    this.camera = createCamera.bind(this)(
      "perspective",
      this.sizes.width / this.sizes.height
    );

    this.scene.add(this.camera);

    /* OrbitControls */
    this.controls = new OrbitControls(this.camera, this.canvas);
    this.controls.enabled = false;
    this.controls.enableDamping = true;
    this.controls.enablePan = false;
    this.controls.enableZoom = false;
    this.controls.autoRotate = true;
    this.controls.autoRotateSpeed = 1;

    /* Post-processing */
    this.renderPass = new RenderPass(this.scene, this.camera);
    this.effectComposer = new EffectComposer(this.renderer);
    this.effectComposer.setPixelRatio(Math.min(2, window.devicePixelRatio));
    this.effectComposer.setSize(this.sizes.width, this.sizes.height);
    this.effectComposer.addPass(this.renderPass);

    const glitchPass = new GlitchPass();
    glitchPass.enabled = false;
    this.effectComposer.addPass(glitchPass);

    this.shaderPassRGBShift = new ShaderPass(RGBShiftShader);
    this.shaderPassRGBShift.enabled = false;
    this.effectComposer.addPass(this.shaderPassRGBShift);

    this.shaderPassPixel = new ShaderPass(PixelShader);
    this.shaderPassPixel.uniforms["resolution"].value = new THREE.Vector2(
      this.sizes.width,
      this.sizes.height
    );
    this.shaderPassPixel.uniforms["resolution"].value.multiplyScalar(
      Math.min(2, window.devicePixelRatio)
    );
    this.shaderPassPixel.uniforms["pixelSize"].value = 10;
    this.shaderPassPixel.enabled = false;
    this.effectComposer.addPass(this.shaderPassPixel);

    this.clock = new THREE.Clock();

    await this.createElements();
    this.render();
  }

  async createElements() {
    this.material = new THREE.ShaderMaterial({
      side: THREE.DoubleSide,
      uniforms: {
        time: {
          value: 0,
        },
        resolution: {
          value: new THREE.Vector4(),
        },
        uFrequency: {
          // value: this.variables.frequency,
          value: this.frequency,
        },
        uAmplitude: {
          // value: this.variables.amplitude,
          value: this.amplitude,
        },
        uMaxDistance: {
          // value: this.variables.maxDistance,
          value: 1.5,
        },
        uColor: {
          // value: this.variables.vertexColor,
          // value: new THREE.Vector3(180 / 255, 180 / 255, 180 / 255),
          value: new THREE.Vector3(255 / 255, 255 / 255, 255 / 255),
        },
        uVertexSize: {
          value: Math.min(2, window.devicePixelRatio),
        },
      },
      vertexShader: await this.vShader.text(),
      fragmentShader: await this.fShader.text(),
    });

    this.geometry = new THREE.IcosahedronBufferGeometry(0.5, 32);
    this.mesh = new THREE.Points(this.geometry, this.material);
    this.scene.add(this.mesh);

    this.resize();
  }

  render() {
    const elapsedTime = this.clock.getElapsedTime();

    this.time += this.speed;
    // this.time += this.variables.speed;
    // this.controls.update();

    this.material.uniforms.time.value = this.time;
    this.material.uniforms.uFrequency.value = WWW.lerp(
      this.material.uniforms.uFrequency.value,
      this.frequency,
      0.01
    );
    this.material.uniforms.uAmplitude.value = WWW.lerp(
      this.material.uniforms.uAmplitude.value,
      this.amplitude,
      0.01
    );

    this.material.uniforms.uVertexSize.value = Math.min(
      2,
      window.devicePixelRatio
    );

    this.camera.position.z =
      Math.floor(10 * WWW.valueMap(this.sizes.width, 375, 1320, 1.7, 1.2)) / 10;
    // console.log(this.camera.position.z);

    // this.scene.background = new THREE.Color(
    //   `rgb(${this.variables.backgroundColor.r * 255},${
    //     this.variables.backgroundColor.g * 255
    //   },${this.variables.backgroundColor.b * 255})`
    // );

    // this.material.uniforms.time.value = this.time;
    // this.material.uniforms.uFrequency.value = this.variables.frequency;
    // this.material.uniforms.uAmplitude.value = this.variables.amplitude;
    // this.material.uniforms.uMaxDistance.value = this.variables.maxDistance;

    // this.material.uniforms.uColor.value = new THREE.Vector3(
    //   this.variables.vertexColor.r,
    //   this.variables.vertexColor.g,
    //   this.variables.vertexColor.b
    // );

    // this.renderer.render(this.scene, this.camera);
    this.effectComposer.render();
    window.requestAnimationFrame(() => {
      this.render();
    });
  }

  changeState(state) {
    switch (state) {
      case 0:
        this.frequency = 3.35;
        this.amplitude = 0.7;
        this.speed = 0.05;
        break;
      case 1:
        this.frequency = 1.0;
        this.amplitude = 0.25;
        this.speed = 0.05;
        break;
      case 2:
        this.frequency = 3.35;
        this.amplitude = 0.7;
        this.speed = 0.05;
        break;
      case 3:
        this.frequency = 9.4;
        this.amplitude = 0.3;
        this.speed = 0.15;
        break;
      case 4:
        this.frequency = 10.0;
        this.amplitude = 0.8;
        this.speed = 0.01;
        break;
      case 5:
        this.frequency = 10.0;
        this.amplitude = 0.8;
        this.speed = 0.01;
        break;
      case 6:
        this.frequency = 10.0;
        this.amplitude = 0.8;
        this.speed = 0.01;
        break;
      default:
        this.frequency = 1.0;
        this.amplitude = 0.25;
        this.speed = 0.05;
    }
  }

  rotate(degree) {
    this.mesh.rotation.y = degree * 0.003;
    this.mesh.rotation.z = degree * 0.001;
  }

  resize() {
    this.sizes.width = this.parentDOM.clientWidth;
    this.sizes.height = this.parentDOM.clientHeight;

    this.renderer.setSize(this.sizes.width, this.sizes.height);
    this.renderer.setPixelRatio(Math.min(2, window.devicePixelRatio));
    this.effectComposer.setSize(this.sizes.width, this.sizes.height);
    this.effectComposer.setPixelRatio(Math.min(2, window.devicePixelRatio));

    resizeCamera.bind(this)(
      this.camera,
      this.sizes.width / this.sizes.height,
      this.mesh
    );
  }
}

function createCamera(type, aspectRatio) {
  let camera = undefined;
  switch (type) {
    case "perspective":
      camera = new THREE.PerspectiveCamera(75, aspectRatio, 0.1, 100);
      break;
    case "orthographic":
      camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 1, 1000);
      break;
    default:
      camera = new THREE.PerspectiveCamera(75, aspectRatio, 0.1, 100);
  }
  return camera;
}

function resizeCamera(camera, aspectRatio, object3D = null) {
  if (camera.type == "OrthographicCamera") {
    let boundingBox = new THREE.Box3().setFromObject(object3D);
    let size = new THREE.Vector3();
    boundingBox.getSize(size);
    this.camera.left = size.x / -2;
    this.camera.right = size.x / 2;
    this.camera.top = size.y / 2;
    this.camera.bottom = size.y / -2;
  } else {
    camera.aspect = aspectRatio;
  }
  camera.updateProjectionMatrix();
}
