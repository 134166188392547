import "bootstrap/dist/js/bootstrap.bundle.js";

import Common from "./Common";
import Homepage from "./pages/Homepage";

window.addEventListener("load", () => {
  let common = new Common();
  common.init();

  const pageHomeDOM = document.querySelector(".page-wrapper#page-home");
  if (pageHomeDOM) {
    let pageHome = new Homepage(pageHomeDOM);
    pageHome.init();
  }
});
