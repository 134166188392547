import Swiper from "swiper/bundle";
import ResponsiveHelper from "../utils/ResponsiveHelper";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ThreeCanvas from "../components/ThreeCanvas";
import { BufferGeometryLoader } from "three";

export default class Homepage {
  constructor(dom) {
    this.dom = dom;
    this.scroller = document.querySelector("[js-scroller]");
    window.addEventListener("resize", () => this.resize());
  }
  init() {
    this.initResponsiveHelper();
    this.initIntro();
    this.initScrollBar();
    this.initSlider();
    this.initScrollTrigger();
    this.initBackground();
  }

  initIntro() {
    let scrollAmount = 0;
    this.scroller.addEventListener("scroll", () => {
      scrollAmount++;
      if (scrollAmount >= 30) {
        this.dom.querySelector("[js-intro]").classList.add("shy");
      }
    });
  }

  initScrollBar() {
    const bar = document.createElement("div");
    bar.classList.add("cust-scrollBar");
    document.body.appendChild(bar);
    this.scroller.addEventListener("scroll", () => {
      const footerHeight = document.querySelector("footer").clientHeight;
      const totalHeight =
        this.dom.clientHeight + footerHeight - window.innerHeight;
      const currentScroll = this.scroller.scrollTop;
      let heightPercent = (currentScroll / totalHeight) * 100;

      bar.style.position = `fixed`;
      bar.style.width = `5px`;
      bar.style.top = `0px`;
      bar.style.right = `0px`;
      bar.style.backgroundColor = `rgba(180,180,180,0.75)`;
      bar.style.zIndex = `1000`;
      bar.style.height = `${heightPercent}%`;
    });
  }

  initSlider() {
    this.slider = new Swiper(".swiper", {
      allowTouchMove: false,
      speed: 700,
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
    });

    this.slider.on("slideChangeTransitionStart", () => {
      const slides = this.slider.slides;
      for (let slide of slides) {
        const scene = slide.querySelector(".scene");
        scene.classList.remove("showInfo");
      }
    });

    this.slider.on("slideChangeTransitionEnd", () => {
      const currentSlide = this.slider.slides[this.slider.activeIndex];
      const scene = currentSlide.querySelector(".scene");
      scene.classList.add("showInfo");
    });
  }

  initScrollTrigger() {
    gsap.registerPlugin(ScrollTrigger);

    let pinEl = this.dom.querySelector("[js-pinEl]");
    let triggerEl = this.dom.querySelector("[js-triggerEl]");
    this.timelineScenes = gsap.timeline({
      scrollTrigger: {
        trigger: triggerEl,
        pin: pinEl,
        scroller: "[js-scroller]",
        pinType: "fixed",
        start: "top top",
        end: "bottom bottom",
        markers: false,
        invalidateOnRefresh: true,
        onUpdate: (self) => {
          const stage = Math.floor(self.progress * 6); //extra stay for last stage
          // console.log(this.slider.activeIndex, stage);
          if (this.slider.activeIndex != stage) {
            this.slider.slideTo(stage);
            this.threeJS.changeState(stage);
          }
        },
      },
    });

    const OFFSETY = 20;
    const exploreWrapper = this.dom.querySelector("[js-explore]");
    const jsScrollReveal = exploreWrapper.querySelectorAll("[js-reveal]");
    gsap.set(jsScrollReveal, { opacity: 0, y: OFFSETY });

    ScrollTrigger.batch(jsScrollReveal, {
      start: `top 80%`,
      end: `bottom top`,
      scroller: "[js-scroller]",
      scrub: true,
      markers: false,
      onEnter: (elements) => {
        gsap.to(elements, this.gsapOptions(true, OFFSETY));
      },
      onLeave: (elements) => {
        gsap.to(elements, this.gsapOptions(false, OFFSETY));
      },
      onEnterBack: (elements) => {
        gsap.to(elements, this.gsapOptions(true, OFFSETY));
      },
      onLeaveBack: (elements) => {
        gsap.to(elements, this.gsapOptions(false, OFFSETY));
      },
    });
  }

  gsapOptions(visible, offset) {
    return {
      opacity: visible ? 1 : 0, //if (visible==true) return 1; else return 0;
      y: visible ? 0 : offset,
      duration: 1.5,
      ease: "back.out(1.7)",
      overwrite: true,
    };
  }

  initBackground() {
    this.threeJS = new ThreeCanvas("[js-canvas]");
    this.threeJS.init();
    this.scroller.addEventListener("scroll", () => {
      this.threeJS.rotate(this.scroller.scrollTop);
    });
  }

  initResponsiveHelper() {
    this.rHelper = new ResponsiveHelper();
    window.addEventListener("onBreakpointChange", (e) => {
      //console.log(e.detail);
    });
  }

  resize() {
    // console.log("resize from Homepage.js");
    clearTimeout(this.resizeTO);
    this.resizeTO = setTimeout(() => {
      this.threeJS.resize();
    }, 10);
  }
}
