export function getDist(p1x, p1y, p2x, p2y) {
  let dx = p2x - p1x;
  let dy = p2y - p1y;
  return Math.sqrt(dx * dx + dy * dy);
}
export function valueMap(num, in_min, in_max, out_min, out_max) {
  return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
}
export function degToRad(deg) {
  return deg * (Math.PI / 180);
}
export function radToDeg(rad) {
  return rad * (180 / Math.PI);
}
export function clamp(number, min, max) {
  return Math.max(min, Math.min(number, max));
}
export function lerp(start, end, amt) {
  return (1 - amt) * start + amt * end;
}
