import * as WWW from "./WWWFunctions";

export default class CustomCursor {
  constructor(dom, elementID) {
    this.dom = dom;
    this.cursor = document.createElement("div");
    this.cursor.setAttribute("id", elementID);
    this.dom.prepend(this.cursor);
    this.lastLeft = 0;
    this.lastTop = 0;
    this.newLeft = 0;
    this.newTop = 0;
    this.clientX = 0;
    this.clientY = 0;

    this.dom.addEventListener("pointermove", (e) => {
      this.cursor.style.display = "block";
      this.clientX = e.clientX;
      this.clientY = e.clientY;
    });

    this.dom.addEventListener("pointerleave", () => {
      this.cursor.style.display = "none";
    });
    this.dom.addEventListener("pointerenter", () => {
      this.cursor.style.display = "block";
    });

    this.dom.removeAttribute("js-customCursor");
    this.dom.setAttribute("js-customCursor-init", "");

    this.updateCursorPosition();
  }

  updateCursorPosition() {
    let bRect = this.dom.getBoundingClientRect();
    this.newLeft = WWW.clamp(this.clientX, 0, bRect.width);
    this.newTop = WWW.clamp(this.clientY, 0, bRect.height);

    const EASING = 0.6;
    let updateLeft = this.lastLeft * EASING + this.newLeft * (1 - EASING);
    let updateTop = this.lastTop * EASING + this.newTop * (1 - EASING);
    this.cursor.style.left = `${updateLeft}px`;
    this.cursor.style.top = `${updateTop}px`;
    this.lastLeft = updateLeft;
    this.lastTop = updateTop;
    window.requestAnimationFrame(() => {
      this.updateCursorPosition();
    });
  }

  addModifierClass(targets, className) {
    for (let target of targets) {
      target.addEventListener("pointerenter", () => {
        this.cursor.classList.add(className);
      });
      target.addEventListener("pointerleave", () => {
        this.cursor.classList.remove(className);
      });
    }
  }
}
